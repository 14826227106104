<template>
  <button
    class="text-white p-2 sm:px-4 sm:py-3 rounded-md w-full flex items-center justify-center text-center"
    style="background-color: #545454 ;"
  >
    <span class="mr-2 text-lg">Pay</span>
    <PayIconComponent />
  </button>
</template>

<script>
import PayIconComponent from "./Icons/PayIconComponent.vue";
export default {
  name: "ButtonPaymentComponent",
  components: {
    PayIconComponent,
  },
};
</script>
