<template>
  <div class="flex justify-center items-center w-screen h-screen">
    <div
      class="w-full lg:w-4/12 xs:w-12/12 px-2 mt-10 mb-10 rounded bg-white shadow-lg max-w-lg"
      style="background-color: rgba(255, 251, 197, 0.38)"
    >
      <div class="border-b border-green">
        <img class="mx-auto w-40 my-5" :src="logo" alt="logo" />
      </div>
      <section class="py-10">
        <div v-if="!showLoader && !showLoader2" class="md:p-12 md:mx-6">
          <div
            v-if="error"
            class="bg-red-600 p-5 m-2 text-white font-light text-center"
          >
            {{ messageError }}
          </div>
          <form @submit="otpVerify">
            <p class="mb-4 text-center">
                Enter the code sent to you by SMS to your phone number
            </p>
            <div class="mb-4">
              <input
                v-model="otpNumber"
                type="number"
                class="form-control block w-2/5 mx-auto my-4 px-3 py-2.5 text-center text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded m-0 focus:bg-white focus:border-green focus:outline-none"
                minlength="4"
                id="exampleFormControlInput1"
                placeholder="-- --"
              />
            </div>
            <div class="flex justify-end">
              <button
                @click="otpVerify"
                type="button"
                class="text-white bg-green font-medium rounded mb-4 text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Vérifier
                <svg
                  class="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
        </div>
        <LoaderComponent
          v-if="showLoader"
          :message="'En cours de chargement...'"
        />
        <LoaderComponent
          v-if="showLoader2"
          :message="'Vérification en cours, patientez...'"
        />
      </section>
      <p class="text-center mb-2">
        <a
          href="https://nexah.net"
          class="text-black text-opacity-80 underline dark:text-white"
          style="font-size: 11px"
          >Fourni par NEXAH</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "@/components/LoaderComponent";
import logo from "../assets/logo.png";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
  name: "Otp",
  inject: ["apiUrl"],
  data() {
    return {
      data: {
        subscription_id: null,
        subscriber_number: null,
      },
      showLoader: true,
      showLoader2: false,
      otpNumber: null,
      error: false,
      messageError: "",
      logo,
      _u: JSON.parse(localStorage.getItem("_u")),
      _c: JSON.parse(localStorage.getItem("_c")),
    };
  },
  async mounted() {
    //const fp = await FingerprintJS.load();

    //const { visitorId } = await fp.get();

    if (this._c && this._c.includes(this.$route.params.id)) {
      const dataUrl = this._u.find((url) => url.code === this.$route.params.id);
      if (dataUrl) {
        window.location.href = dataUrl.url;
      } else {
        this.sendOtp("");
      }
    } else {
      this.sendOtp("");
    }
  },
  methods: {
    otpVerify(e) {
      e.preventDefault();
      this.showLoader2 = true;
      this.error = false;
      const optNumber = this.otpNumber;
      const data = {
        otp: optNumber,
        code: this.$route.params.id,
      };
      axios
        .post(`${this.apiUrl}/verifyotp`, data)
        .then((response) => {
          if (response.data.errcode == 200) {
            if (!this._c) {
              this._c = [];
            }
            this._c.push(this.$route.params.id);
            const url = {
              code: this.$route.params.id,
              url: response.data.data.lien,
            };
            if (!this._u) {
              this._u = [];
            }
            this._u.push(url);
            localStorage.setItem("_c", JSON.stringify(this._c));
            localStorage.setItem("_u", JSON.stringify(this._u));
            //localStorage.setItem("otp", data.otp);
            //localStorage.setItem("url", response.data.data.lien);
            window.location.href = response.data.data.lien;
          } else {
            this.showLoader2 = false;
            this.error = true;
            this.messageError = response.data.msg; //"Code incorrect, Saisir le code reçu par SMS !";
          }
        })
        .catch((error) => {
          this.showLoader2 = false;
          this.messageError =
            "Oops! A problem validating your OTP code, reload the page or try again later.";
          console.log(error.message);
        });
    },

    sendOtp(visitorId) {
      axios
        .post(`${this.apiUrl}/sendotp`, {
          code: this.$route.params.id,
          device: visitorId,
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            if (response.data.data) {
              window.location.href = response.data.data;
            } else {
              this.showLoader = false;
            }
          } else {
            this.showLoader = false;
            this.error = true;
            this.messageError = response.data.msg;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.messageError =
            "An error has occurred, please try again later!";
        });
    },
  },
  components: {
    LoaderComponent,
  },
};
</script>

<style scoped>
.shawdow-dark {
  box-shadow: -1px 1px 5px 2px #8c8d8d;
}
</style>
